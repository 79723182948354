import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardTitle, CardHeader } from "../../components/ui/card";
import { Badge } from "../../components/ui/badge";
import apiServices from "../../services/apiServices";
import createdOrder from "../../assets/status/createdOrder.svg";
import produced from "../../assets/status/produced.svg";
import completedOrder from "../../assets/status/completeOrder.svg";
import distribution from "../../assets/status/distribution.svg";
import notCompleteOrder from "../../assets/status/notCompleteOrder.svg";
import pendingApproval from "../../assets/status/pendingApproval.svg";
import referralStage from "../../assets/status/referralStage.svg";
import warehouse from "../../assets/status/warehouse.svg";
import printBarcode from "../../assets/printBarcode.svg";
import plvLogo from "../../assets/plvLogo.svg";
import { get } from "../../services/cache";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import Barcode from "react-barcode";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { toast } from "sonner";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ReactToPrint from "react-to-print";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class BarcodeToPrint extends React.Component {
  render() {
    return (
      <div className="relative">
        <div className="p-4 flex flex-col items-center h-[100vh] justify-center">
          <Barcode
            value={this.props.value}
            width={8}
            fontSize={40}
            textMargin={30}
            height={200}
          />
        </div>
      </div>
    );
  }
}

const OrderCard = ({
  orderInfo,
  isSelected,
  handleCardClick,
  isOpen,
  toggleAccordion,
  isSelectionActive,
  refreshOrders,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = React.useState(orderInfo);
  const [value, setValue] = React.useState(order?.status);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [checkRole, setCheckRole] = useState("");
  const [qtyDialog, setQtyDialog] = useState(false);
  const [qtyValue, setQtyValue] = useState("");
  const BarcodeRef = useRef();

  const handleCardClickNavigate = () => {
    if (!isSelectionActive && !isSelectOpen) {
      navigate(`/order/${order.orderNumber}`);
      localStorage.setItem("pendingOrderDetails", JSON.stringify(order));
    } else {
      handleCardClick(order.orderNumber);
    }
  };

  const handleValueChange = async (e) => {
    const body = {
      id: order.number,
      orderNumbers: [order.orderNumber],
      status: e,
    };
    try {
      const result = await apiServices.post("/v1/order/set-status", body);
      if (result.isSuccess) {
        setValue(e);
        toast("Statü Güncellendi", {
          description: `Statü "${status[e].label}" olarak güncellendi.`,
          cancel: {
            label: "Kapat",
          },
        });
        refreshOrders();
      } else {
        console.error("Error Message:", result.errorMessage);
        toast("Statü Güncelleme Hatası", {
          description: `Statü güncellenirken hata oluştu.`,
          cancel: {
            label: "Kapat",
          },
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    if (e === 2) {
      setQtyDialog(true);
    }
  };

  useEffect(() => {
    const role = get("claims")?.data?.jobTitle
      ? get("claims")?.data?.jobTitle
      : get("claims");
    if (
      role === "Software Development Engineer" ||
      role === "CHEF DE PROJET" ||
      role === "Merchandiser"
    ) {
      setCheckRole("Admin");
    } else if (role === "Store" || get("claims")?.data?.hasDuty === true) {
      setCheckRole("store");
    } else if (role === "Resp Appro et Ventes Zone Douanière - RS") {
      setCheckRole("warehouse");
    } else if (role?.data?.email) {
      setCheckRole("supplier");
    }
  }, []);

  const status = [
    {
      value: "0",
      label: "Oluşturuldu",
    },
    {
      value: "1",
      label: "Üretiliyor",
    },
    {
      value: "2",
      label: "Sevk Aşamasında",
    },
    {
      value: "3",
      label: "Depoda",
    },
    {
      value: "4",
      label: "Dağıtımda",
    },
    {
      value: "5",
      label: "Onay Bekliyor",
    },
    {
      value: "6",
      label: "Tamamlandı",
    },
    {
      value: "7",
      label: "Tamamlanmadı",
    },
  ];

  const handleQtyValueChange = (e) => {
    setQtyValue(e.target.value);
  };

  const handleSetQtyValue = async () => {
    if (qtyValue > 0) {
      const body = {
        orderNumber: order.orderNumber,
        packageCount: qtyValue,
      };
      try {
        const result = await apiServices.post(
          "/v1/order/set-packagecount",
          body
        );
        if (result.isSuccess) {
          toast("Adet Güncellendi", {
            description: `Item Adeti ${qtyValue} olarak güncellendi.`,
            cancel: {
              label: "Kapat",
            },
          });
          setQtyDialog(false);
        } else {
          console.error("Error Message:", result.errorMessage);
          toast("Adet Güncelleme Hatası", {
            description: `Item Adeti güncellenirken hata oluştu.`,
            cancel: {
              label: "Kapat",
            },
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };
  return (
    <div className="flex relative">
      <div
        className={`absolute w-full h-full ${isSelectionActive ? " z-10 cursor-pointer" : ''}`}
        type="single"
        role="button"               
        tabIndex={0}                
        onClick={handleCardClickNavigate}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") { 
            handleCardClickNavigate(e);
          }
        }}
      />
      <div
        className={` w-3 rounded-l-lg border mb-3  duration-300 ${
          isSelected && isSelectionActive
            ? "bg-[#3643BA] border-[#3643BA]"
            : "bg-white"
        }`}
      />

      <Card
        className={`border w-full rounded-l-none border-l-0 mb-3 cursor-pointer flex flex-col ${
          isSelected && isSelectionActive
            ? "border border-[#3643BA] border-l-0"
            : ""
        }`}
        // onClick={handleCardClickNavigate} // Kart tıklandığında handleCardClickNavigate fonksiyonunu çağır
      >
        <CardHeader className="flex-row justify-between items-center py-3 px-0 border border-[#999999] border-dashed border-x-0 border-t-0 mx-3">
          <CardTitle className="text-base">
            Sipariş ID: {order?.orderNumber}
          </CardTitle>

          <Select
            onOpenChange={(open) => setIsSelectOpen(open)}
            value={value}
            onValueChange={handleValueChange}
            className="p-5"
          >
            <SelectTrigger className="w-[180px] rounded-3xl h-7 bg-[#EFF4FE] text-[#444444] border-none relative focus:ring-[#3643BA]">
              <SelectValue />
            </SelectTrigger>

            <SelectContent className="rounded-3xl py-2 border-[#3643BA]">
              <SelectGroup>
                <SelectItem
                  value={0}
                  disabled={checkRole !== "supplier" && checkRole !== "Admin"}
                >
                  Oluşturuldu{" "}
                  <img
                    src={createdOrder}
                    alt=""
                    className="absolute top-2 right-3 ml-3"
                  />
                </SelectItem>
                <SelectItem
                  value={1}
                  disabled={checkRole !== "supplier" && checkRole !== "Admin"}
                >
                  Üretiliyor{" "}
                  <img
                    src={produced}
                    alt=""
                    className="absolute top-2 right-3"
                  />{" "}
                </SelectItem>
                <SelectItem
                  value={2}
                  disabled={checkRole !== "supplier" && checkRole !== "Admin"}
                >
                  Sevk Aşamasında{" "}
                  <img
                    src={referralStage}
                    alt=""
                    className="absolute top-2.5 right-3"
                  />{" "}
                </SelectItem>

                <SelectItem
                  value={3}
                  disabled={checkRole !== "warehouse" && checkRole !== "Admin"}
                >
                  Depoda{" "}
                  <img
                    src={warehouse}
                    alt=""
                    className="absolute top-2 right-3"
                  />{" "}
                </SelectItem>
                <SelectItem
                  value={4}
                  disabled={checkRole !== "warehouse" && checkRole !== "Admin"}
                >
                  Dağıtımda{" "}
                  <img
                    src={distribution}
                    alt=""
                    className="absolute top-2 right-3"
                  />{" "}
                </SelectItem>
                <SelectItem
                  value={5}
                  disabled={checkRole !== "store" && checkRole !== "Admin"}
                >
                  Onay Bekliyor{" "}
                  <img
                    src={pendingApproval}
                    alt=""
                    className="absolute top-2 right-3"
                  />{" "}
                </SelectItem>
                <SelectItem
                  value={7}
                  disabled={checkRole !== "store" && checkRole !== "Admin"}
                >
                  Tamamlandı{" "}
                  <img
                    src={completedOrder}
                    alt=""
                    className="absolute top-2 right-3"
                  />{" "}
                </SelectItem>
                <SelectItem
                  value={6}
                  disabled={checkRole !== "store" && checkRole !== "Admin"}
                >
                  Tamamlanmadı{" "}
                  <img
                    src={notCompleteOrder}
                    alt=""
                    className="absolute top-2 right-3"
                  />{" "}
                </SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </CardHeader>
        
        {
          <Dialog open={qtyDialog}>
            <DialogContent className="sm:max-w-[425px] rounded-lg w-11/12">
              <DialogHeader>
                <DialogTitle className="bg-[#3643BA] rounded-t-lg p-3 text-base text-white text-left">
                  Paket Adeti
                  <ReactToPrint
                    trigger={() => (
                      <button className="absolute right-4 top-3 gap-2 z-10 flex items-center">
                        <img src={printBarcode} alt="" />
                        Barkod Yazdır
                      </button>
                    )}
                    content={() => BarcodeRef.current}
                  />
                </DialogTitle>
              </DialogHeader>
              <div className="p-4 flex flex-col items-center">
                <span className="w-full">
                  Sipariş içerisindeki paket adetini giriniz !
                </span>
                <Input className="my-4" onChange={handleQtyValueChange} />
                <Barcode value={order.orderNumber} width={3} />
                <span className="hidden">
                  <BarcodeToPrint ref={BarcodeRef} value={order.orderNumber} />
                </span>
                <div className="flex place-content-evenly w-full mt-3">
                  <Button
                    className="bg-[#949494] w-32 rounded-3xl"
                    onClick={() => {
                      setQtyDialog(false);
                    }}
                  >
                    İptal
                  </Button>
                  <Button
                    className="bg-[#3643BA] w-32 rounded-3xl"
                    onClick={handleSetQtyValue}
                    disabled={!qtyValue}
                  >
                    Kaydet
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        }
        <div className="p-3" type="single">
          <div className="flex mts-4 space-x-4 w-full">
            <div className="flex-shrink-0">
              <img src={plvLogo} alt="" className="w-[80px] h-[80px]" />
            </div>
            <div className="flex-grow">
              <h3 className="text-sm font-semibold text-gray-900">
                {order.storeName ? order.storeName : "MAĞAZA PLV GÖRSEL"}
              </h3>
              <p className="text-gray-700 text-sm">
                Miktar: {order?.orderDetailCount}
              </p>
            </div>
            <div className="flex-grow flex flex-col justify-between items-end ml-0">
              <Badge
                variant="secondary"
                className="rounded-lg w-max bg-[#FFCD4E]"
              >
                {order?.orderDetailSumUnitPrice} ₺
              </Badge>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OrderCard;
